<template>
  <Layout>
    <PageHeader
      :title="newClient.isInvestor ? 'Update Investor' : 'Update Company'"
    />
    <div class="row">
      <div class="col-12 col-sm-7">
        <div class="card">
          <div class="row">
            <div class="col-12 col-sm-3">
              <div class="card-body">
                <div class="met-profile">
                  <div class="met-profile-main">
                    <div class="met-profile-main-pic">
                      <file-upload
                        ref="upload"
                        class="file profile-img"
                        v-model="image"
                        accept="image/png, image/gif, image/jpeg"
                        extensions="gif,jpg,jpeg,png"
                      >
                        <img
                          :src="
                            newClient.profileImg
                              ? $apiDomain + newClient.profileImg
                              : require('@/assets/images/no-image.jpg')
                          "
                          alt="Profil Fotoğrafı"
                          class="rounded-circle c-size-prof"
                        />
                        <span class="fro-profile_main-pic-change">
                          <i class="fas fa-camera"></i>
                        </span>
                      </file-upload>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-9">
              <div class="card-body">
                <div class="form-group mt-2">
                  <label
                    >{{ newClient.isInvestor ? "Fon" : "Company" }} Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="
                      newClient.isInvestor ? 'Fon Name' : 'Kurum Name'
                    "
                    autocomplete="nope"
                    v-model="newClient.name"
                    @blur="$v.newClient.name.$touch()"
                    :class="{
                      'is-invalid': $v.newClient.name.$error,
                    }"
                  />
                  <div class="invalid-feedback">Required</div>
                </div>

                <div class="form-group mt-2">
                  <label>Adress</label>
                  <textarea
                    type="text"
                    class="form-control"
                    placeholder="Adress"
                    autocomplete="nope"
                    v-model="newClient.address"
                  ></textarea>
                </div>

                <div class="float-right">
                  <button
                    type="button"
                    class="
                      btn btn-md btn-primary
                      waves-effect waves-light
                      pr-4
                      pl-4
                    "
                    @click="updateClient"
                  >
                    Update
                  </button>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
/*eslint-disable*/
import { HTTP } from "@/main-source";
import router from "@/router";
import {
  required,
  minLength,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

export default {
  name: "NewInvestor",
  data() {
    return {
      passInputType: "password",
      cities: [],
      image: [],
      selectedCity: null,
      newClient: {
        name: null,
        address: null,
        profileImg: null,
      },
    };
  },
  validations: {
    newClient: {
      name: {
        required: requiredIf(function (newClientOb) {
          return newClientOb.isInvestor == true;
        }),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
  },
  created() {
    HTTP.get("firms/" + this.$route.params.id).then((result) => {
      this.newClient = result.data.rows[0];
    });
  },
  methods: {
    passwordToggle: function () {
      this.passInputType == "password"
        ? (this.passInputType = "text")
        : (this.passInputType = "password");
    },
    updateClient: function () {
      HTTP.put("firms/" + this.$route.params.id, this.newClient)
        .then(() => {
          router.push({
            name: this.newClient.isInvestor
              ? "investor-detail"
              : "company-detail",
            params: {
              id: this.$route.params.id,
            },
          });
        })
        .catch((error) => {
          console.log("🚀 ~ file: new.vue ~ line 312 ~ error", error);
        });
    },
  },
  watch: {
    image: function () {
      let fd = new FormData();

      fd.append("files", this.image[0].file, this.image[0].name);

      HTTP.post("/files/", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((res) => {
          this.newClient.profileImg = res.data[0].path;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
